// GENERATED FILE
// This file is automatically generated by "create-modules.ts" whenever you add or remove a module.

import ModuleTypes from '../view-models/ModuleTypes';

/**
 * List all Modules that can be imported here.
 * Use `import()` to enable async loading of only the modules we need on a page.
 */
const dynamicModulesMap: { [key: string]: () => Promise<any> } = {
  [ModuleTypes.ArticlePage]: () =>
    import(/* webpackChunkName: 'article-page' */ './ArticlePage/ArticlePage'),
  [ModuleTypes.ContactPage]: () =>
    import(/* webpackChunkName: 'contact-page' */ './ContactPage/ContactPage'),
  [ModuleTypes.CookieDeclaration]: () =>
    import(
      /* webpackChunkName: 'cookie-declaration' */ './CookieDeclaration/CookieDeclaration'
    ),
  [ModuleTypes.ElectionTypePage]: () =>
    import(
      /* webpackChunkName: 'election-type-page' */ './ElectionTypePage/ElectionTypePage'
    ),
  [ModuleTypes.Footer]: () =>
    import(/* webpackChunkName: 'footer' */ './Footer/Footer'),
  [ModuleTypes.FrontPage]: () =>
    import(/* webpackChunkName: 'front-page' */ './FrontPage/FrontPage'),
  [ModuleTypes.Header]: () =>
    import(/* webpackChunkName: 'header' */ './Header/Header'),
  [ModuleTypes.HelpPage]: () =>
    import(/* webpackChunkName: 'help-page' */ './HelpPage/HelpPage'),
  [ModuleTypes.MitIdPage]: () =>
    import(/* webpackChunkName: 'mit-id-page' */ './MitIdPage/MitIdPage'),
  [ModuleTypes.NotFound]: () =>
    import(/* webpackChunkName: 'not-found' */ './NotFound/NotFound'),
  [ModuleTypes.PartyPage]: () =>
    import(/* webpackChunkName: 'party-page' */ './PartyPage/PartyPage'),
  [ModuleTypes.ThePartiesPage]: () =>
    import(
      /* webpackChunkName: 'the-parties-page' */ './ThePartiesPage/ThePartiesPage'
    ),
  [ModuleTypes.Unavailable]: () =>
    import(/* webpackChunkName: 'unavailable' */ './Unavailable/Unavailable'),
};

export default function getModule(name: ModuleTypes) {
  return dynamicModulesMap[name];
}
