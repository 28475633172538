// It's important that we import 'core-js' before everything else, including React.
// Importing it later will break React in IE11
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';

import { IError } from './api/api-types';
import { setPageDataCache } from './api/hooks/usePageData';
import { handleQueryError } from './api/query-error';
import App from './application/App';
import { ModuleInstance, preloadModules } from './application/modules-utils';
import Router from './application/Router';
import { initSentry } from './application/sentry';
import loadPolyfills from './utils/polyfills';
import { normalizePathname } from './utils/url-helpers';
import { HeadlessViewModel } from './view-models/HeadlessViewModel';
import { HtmlConfigViewModel } from './view-models/HtmlConfigViewModel';
import { initPlausible } from './utils/tracking';

/**
 *  Define the default rules for how we query data in the application.
 *
 */
let queryConfig: ReactQueryConfig<unknown, IError> = {
  queries: {
    staleTime: 1000 * 60 * 5,
    cacheTime: 300000,
    refetchOnWindowFocus: false,
    retry: (failureCount, { status }) => {
      if (failureCount >= 2) return false;
      // Retry on an invalid server response
      if (status) return status >= 500;
      // retry after retryDelay
      return true;
    },
    onError: handleQueryError,
  },
};

/**
 * Initialize the client code.
 */
export function init(data: HeadlessViewModel) {
  // Init sentry error tracking
  if (process.env.NODE_ENV !== 'test') {
    initSentry();
  }

  // Initial data is JSON string, so let's parse it now.
  const config: HtmlConfigViewModel = JSON.parse(
    document.querySelector('script[data-config]')?.innerHTML ?? '"{}"',
  );

  initPlausible(config.keys?.plausible);

  // Preload polyfills and modules before rendering the app
  return Promise.all([preloadModules(data.modules), loadPolyfills()]).then(
    ([moduleInstances]) => {
      renderApp(moduleInstances, data, config);
    },
  );
}

function renderApp(
  modules: ModuleInstance[],
  { meta }: HeadlessViewModel,
  config: HtmlConfigViewModel,
) {
  const root = document.getElementById('app');

  const initialData = {
    pathname: normalizePathname(window.location.pathname) || '/',
    meta,
    modules,
  };

  // Set Initial data for the route here, so it's ready for all consumers.
  setPageDataCache(initialData.pathname, initialData);

  // Add the initial data to the page cache, so we don't preload it again.
  if (root) {
    const content = (
      <ReactQueryConfigProvider config={queryConfig}>
        <Router>
          <App config={config} initialData={initialData} />
        </Router>
      </ReactQueryConfigProvider>
    );
    if (process.env.NODE_ENV !== 'test') {
      ReactDOM.hydrate(content, root);
    } else {
      // When rendering in the test, we aren't rehydrating sever output, so use the render method.
      // This could also be done as a check that only uses hydrate if #app contains children
      ReactDOM.render(content, root);
    }
  } else {
    throw new Error('Failed to find #app');
  }
}

if (process.env.NODE_ENV !== 'test') {
  // Init the client now - Moved inside init function to make it testable :)
  if (!window.VERSION) {
    window.VERSION = process.env.VERSION;

    const initialData = JSON.parse(
      document.querySelector('script[data-initial]')?.innerHTML ?? '"{}"',
    );

    init(initialData);
  }
}
