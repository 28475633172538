import { rem } from 'polished';
import { ObjectOrArray } from 'styled-system';
import { StandardProperties } from 'csstype';

import { Colors } from './colors';
import { FontWeight } from './typography';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Flat = 'flat',
  Link = 'link',
}

export const buttons: ObjectOrArray<StandardProperties> = {
  [ButtonVariant.Primary]: {
    background: Colors.White,
    border: 'solid 2px',
    textTransform: 'uppercase',
    fontWeight: FontWeight.Medium,
    letterSpacing: '1.2px',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    lineHeight: 1.6,
    fontSize: rem(13),
    color: Colors.Black,
    borderColor: Colors.Primary,
    transition: 'border-color .3s ease',
    '&:hover': {
      borderColor: Colors.Black,
    },
    '&[disabled]': {
      borderColor: Colors.LightGrey,
      color: Colors.BattleshipGrey,
    },
  },
  [ButtonVariant.Secondary]: {
    background: Colors.White,
    border: 'solid 2px',
    textTransform: 'uppercase',
    fontWeight: FontWeight.Medium,
    letterSpacing: '1.2px',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    lineHeight: 1.6,
    fontSize: rem(13),
    color: Colors.Secondary,
    borderColor: Colors.Secondary,
    transition: 'border-color .3s ease, color 0.3s ease',
    '&:hover': {
      color: Colors.Black,
      borderColor: Colors.Black,
    },
    '&[disabled]': {
      borderColor: Colors.LightGrey,
      color: Colors.BattleshipGrey,
    },
  },
  [ButtonVariant.Flat]: {
    color: Colors.Secondary,
    background: 'transparent',
    textTransform: 'uppercase',
    border: 'none',
    fontWeight: FontWeight.Medium,
    transition: 'color 0.3s ease',
    fontSize: rem(13),
    letterSpacing: '1.2px',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    lineHeight: 1.6,
    '&:hover': {
      color: Colors.Black,
    },
  },
  [ButtonVariant.Link]: {
    color: Colors.Secondary,
    background: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: FontWeight.Medium,
    transition: 'color 0.3s ease',
    fontSize: rem(13),
    letterSpacing: '1.2px',
    lineHeight: 1,
    '&:hover': {
      color: Colors.Black,
    },
  },
};

export default buttons;
