import { QueryConfig, queryCache, useQuery } from 'react-query';

import {
  ModuleInstance,
  preloadModules,
} from '../../application/modules-utils';
import { normalizePathname } from '../../utils/url-helpers';
import { HeadlessViewModel } from '../../view-models/HeadlessViewModel';
import { MetaViewModel } from '../../view-models/MetaViewModel';
import { IError } from '../api-types';
import { fetcher } from '../fetcher';
import { captureMessage, Severity, withScope } from '@sentry/browser';

export type PageResult = {
  pathname: string;
  meta: MetaViewModel;
  modules: ModuleInstance[];
};

export function setPageDataCache(pathname: string, data: PageResult) {
  const normalized = normalizePathname(pathname);
  if (normalized) queryCache.setQueryData(normalized, data);
}

export function prefetchPageDataCache(pathname: string) {
  return queryCache.prefetchQuery(normalizePathname(pathname), loadPageData, {
    staleTime: Infinity,
    retry: false,
  });
}

async function loadPageData(pathname: string): Promise<PageResult> {
  const cacheData = queryCache.getQuery(pathname);
  // For some reason initialData isn't detected correctly when fetching the page.
  // Check if the page is already cached, and use the result
  if (cacheData && cacheData.state.status === 'success') {
    return cacheData.state.data as PageResult;
  }

  const result = await fetcher<HeadlessViewModel>(pathname + '?json');
  // Preload the modules on the page, so all the scripts are ready
  const preloadedModules = await preloadModules(result.modules);
  // If the result has required fields, update the page.

  if (normalizePathname(result.meta.pathname || '') !== pathname) {
    withScope((scope) => {
      scope.setTag('type', 'fetch');
      scope.setExtra('pathname', pathname);
      scope.setExtra('result', result);
      captureMessage(
        'Mismatch between "pathname" of client and returned data. This indicates an async data problem on the server.',
        Severity.Critical,
      );
    });
  }

  return {
    meta: result.meta,
    modules: preloadedModules,
    pathname,
  };
}

export default function usePageData(
  pathname: string | null,
  options?: QueryConfig<PageResult, IError>,
) {
  return useQuery(normalizePathname(pathname), loadPageData, {
    staleTime: Infinity,
    enabled: !!pathname,
    retry: false,
    ...options,
  });
}
