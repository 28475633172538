import * as React from 'react';
import { ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';

const FriendlyError = !process.env.SERVER
  ? React.lazy(() => import('../components/FriendlyError/FriendlyError'))
  : null;

type Props = {
  moduleName: string;
  children?: React.ReactNode;
};

type State = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

class ErrorBoundary extends React.Component<Props, State> {
  static displayName = 'ErrorBoundary';
  static defaultProps = {
    moduleName: 'Unknown',
  };

  state: State = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    },
  ) {
    this.setState({ error, errorInfo });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      scope.setTag('module', this.props.moduleName);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      const child = React.Children.only(this.props.children);
      const props = React.isValidElement(child) ? child.props : {};
      const { error, errorInfo } = this.state;

      return (
        FriendlyError && (
          <React.Suspense fallback={<div />}>
            <FriendlyError
              moduleName={this.props.moduleName}
              moduleProps={props}
              error={error}
              errorInfo={errorInfo}
              handleRetry={() => {
                this.setState({ error: undefined, errorInfo: undefined });
              }}
            />
          </React.Suspense>
        )
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
