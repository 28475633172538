import React, { createContext, useContext, useEffect, useState } from 'react';
import { configureScope } from '@sentry/browser';
import Cookies from 'js-cookie';
import { queryCache } from 'react-query';

import { useRouter } from '../application/Router';
import { generateRedirectUrl } from '../utils/url-helpers';

export enum UserState {
  LoggedOut = 'loggedout',
  LoggedIn = 'loggedin',
  SessionExpired = 'expired',
  Forbidden = 'forbidden',
}

type Auth = {
  /** Undefined until we know for sure if the user logged in cookie is set. */
  userState?: UserState;
  /** URL to send the user to when they need to login */
  loginUrl?: string;
  /** URL to send the user to when they need to logout */
  logoutUrl?: string;
  /** update the user state */
  updateUserState?: (state: UserState) => void;
};

export const AuthContext = createContext<Auth>({
  // Default to a logged out state if trying to render component outside the AuthContext
  userState: UserState.LoggedOut,
});

type Props = {
  children: React.ReactNode;
  /** Name of cookie that should exist if the user is authenticated */
  authCookie?: string;
  /** Url to send the user to when they want to log in */
  loginUrl?: string;
  /** Url to send the user to when logging out */
  logoutUrl?: string;
  /** @internal */
  initialToken?: string;
};

export type AuthUrls = {
  loginUrl?: string;
  logoutUrl?: string;
};

export function AuthProvider({
  loginUrl,
  logoutUrl,
  authCookie,
  initialToken,
  children,
}: Props) {
  const { pathname } = useRouter();
  const [userState, setUserState] = useState<UserState | undefined>(undefined);
  const [urls, setUrls] = useState<AuthUrls>({});

  useEffect(() => {
    if (!authCookie) return;
    // Check if the Cookie exists. If it does, assume the user is logged in.
    if (initialToken) {
      Cookies.set(authCookie, initialToken);
    }

    const loggedIn = !!Cookies.get(authCookie);

    setUserState((state) => {
      if (state) return state;
      return loggedIn ? UserState.LoggedIn : UserState.LoggedOut;
    });
    configureScope((scope) => {
      scope.setExtra('logged-in', loggedIn);
    });

    if (process.env.STORYBOOK) {
      // Refresh the userdata in the storybook, since it can change value.
      queryCache.invalidateQueries('user');
    }

    return () => {
      if (initialToken) {
        Cookies.remove(authCookie);
      }
    };
  }, [authCookie, initialToken]);

  useEffect(() => {
    setUrls({
      // Format the auth urls so they contain the current redirect URL
      loginUrl: loginUrl && generateRedirectUrl(loginUrl),
      logoutUrl: logoutUrl && generateRedirectUrl(logoutUrl, '/'),
    });
  }, [pathname, loginUrl, logoutUrl]);

  return (
    <AuthContext.Provider
      value={{
        userState,
        // Ensure the user can't login again, if they are already logged in.
        // This could be an issue when we interpolate the login URL in rich text fields.
        loginUrl: userState !== UserState.LoggedIn ? urls.loginUrl : '/',
        logoutUrl: userState !== UserState.LoggedOut ? urls.logoutUrl : '/',
        updateUserState: (state) => {
          if (
            state === UserState.SessionExpired ||
            state === UserState.LoggedOut
          ) {
            if (authCookie) Cookies.remove(authCookie);
          }
          setUserState(state);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

useAuth.displayName = 'useAuth';
