// GENERATED FILE
// This file is automatically generated by "create-modules.ts" whenever you add or remove a module.

/**
 * All the known modules in the application
 */
enum ModuleTypes {
  Unknown = 'unknown',
  ArticlePage = 'ArticlePage',
  ContactPage = 'ContactPage',
  CookieDeclaration = 'CookieDeclaration',
  ElectionTypePage = 'ElectionTypePage',
  Footer = 'Footer',
  FrontPage = 'FrontPage',
  Header = 'Header',
  HelpPage = 'HelpPage',
  MitIdPage = 'MitIdPage',
  NotFound = 'NotFound',
  PartyPage = 'PartyPage',
  ThePartiesPage = 'ThePartiesPage',
  Unavailable = 'Unavailable',
}

export default ModuleTypes;
