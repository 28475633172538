import { useEffect } from 'react';

import usePageData from '../api/hooks/usePageData';
import { MetaViewModel } from '../view-models/MetaViewModel';
import { useRouter } from './Router';

export function generateTitle(meta: MetaViewModel): string | undefined {
  if (!meta) return '';
  return meta.title
    ? meta.title !== meta.siteName && meta.siteName
      ? `${meta.title} | ${meta.siteName}`
      : meta.title
    : meta.siteName;
}

type Props = {
  value?: string | null;
};

/**
 * Update the document title whenever the meta data changes
 */
function DocumentTitle({ value }: Props) {
  const { pathname } = useRouter();

  const { data } = usePageData(pathname);
  const meta = data?.meta;

  useEffect(() => {
    if (!meta) return;
    const metaTitle = generateTitle(meta);

    if (metaTitle) {
      document.title = value ? value + ' | ' + metaTitle : metaTitle;
    }
  }, [meta, value]);
  return null;
}

export default DocumentTitle;
