import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

/**
 * Determine the current environment by looking at the host
 * */
function findEnvironment(host: string) {
  if (
    host.startsWith('localhost') ||
    host.match(/^\d/) ||
    process.env.NODE_ENV !== 'production'
  ) {
    return 'local';
  }
  // Extract the env from the first part of the hostname.
  const [, env] =
    (host.includes('ve-website')
      ? host.match(/^(\w+)-ve-website/)
      : host.match(/(\w+).vaelgererklaering.dk/)) || [];
  return env && env !== 'www' ? env : 'production';
}

export function initSentry() {
  const environment = findEnvironment(document.location.host);

  Sentry.init({
    // Our Sentry DSN, indicating the URL that should handle our logging.
    dsn:
      'https://7fbc75a6b2ba454c96a60559efe68127@o20326.ingest.sentry.io/5202290',
    environment,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise',
      'Invariant Violation',
      'ResizeObserver loop limit exceeded',
      'document.getElementsByClassName.ToString is not a function',
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    integrations:
      process.env.NODE_ENV !== 'production'
        ? [new Sentry.Integrations.Breadcrumbs({ console: false })]
        : undefined,
    release: process.env.VERSION,
    beforeSend: (event, hint) => {
      if (process.env.NODE_ENV !== 'production') {
        if (event.level === Severity.Warning) {
          // eslint-disable-next-line no-console
          console.warn(event.message, event.extra);
        } else {
          // eslint-disable-next-line no-console
          console.error(
            hint?.originalException ||
              hint?.syntheticException ||
              event.message,
            event.extra,
          );
        }
        return null; // this drops the event and nothing will be sent to sentry
      }
      if (environment === 'local') return null;
      return event;
    },
  });
}
