import { css } from '@emotion/react';

import robotoMonoMedium_woff from './fonts/roboto-mono-v7-latin-500.woff';
import robotoMonoMedium_woff2 from './fonts/roboto-mono-v7-latin-500.woff2';
import robotoMono_woff from './fonts/roboto-mono-v7-latin-regular.woff';
import robotoMono_woff2 from './fonts/roboto-mono-v7-latin-regular.woff2';
import rubikMedium_woff from './fonts/rubik-v9-latin-500.woff';
import rubikMedium_woff2 from './fonts/rubik-v9-latin-500.woff2';
import rubikBold_woff from './fonts/rubik-v9-latin-700.woff';
import rubikBold_woff2 from './fonts/rubik-v9-latin-700.woff2';
import rubikItalic_woff from './fonts/rubik-v9-latin-italic.woff';
import rubikItalic_woff2 from './fonts/rubik-v9-latin-italic.woff2';
import rubik_woff from './fonts/rubik-v9-latin-regular.woff';
import rubik_woff2 from './fonts/rubik-v9-latin-regular.woff2';

export const preloadFonts = [robotoMonoMedium_woff2, rubik_woff2];

export const fonts = css`
  @font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'), local('RobotoMono-Regular'),
      url(${robotoMono_woff2}) format('woff2'),
      url(${robotoMono_woff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono Medium'), local('RobotoMono-Medium'),
      url(${robotoMonoMedium_woff2}) format('woff2'),
      url(${robotoMonoMedium_woff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Rubik';
    src: url(${rubik_woff2}) format('woff2'), url(${rubik_woff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Rubik';
    src: url(${rubikItalic_woff2}) format('woff2'),
      url(${rubikItalic_woff}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Rubik';
    src: url(${rubikMedium_woff2}) format('woff2'),
      url(${rubikMedium_woff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Rubik';
    src: url(${rubikBold_woff2}) format('woff2'),
      url(${rubikBold_woff}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }
`;
