import { Theme } from '@emotion/react';
import { rem } from 'polished';

import { Colors } from './colors';

export enum TextVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  Paragraph17 = 'paragraph-17',
  Paragraph = 'paragraph',
  Paragraph12 = 'paragraph-12',
  Alert = 'alert',
}

export enum FontWeight {
  Hairline = 100,
  Thin = 200,
  Light = 300,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Black = 900,
}

export enum FontFamily {
  Body = 'body',
  Heading = 'heading',
  Mono = 'mono',
}

const typography: Omit<Theme, 'colors'> = {
  fontSizes: [
    '0.75rem', // 12px
    '0.875rem', // 14px
    '1rem', // 16px
    '1.25rem', // 20px
    '1.5rem', //24px
    '1.75rem', //28px
    '2rem', // 32px
    '2.5rem', // 40px,
    '3rem', // 48px,
    '4rem', // 64px,
  ],
  fonts: {
    [FontFamily.Heading]: 'inherit',
    [FontFamily.Body]:
      'Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    [FontFamily.Mono]: `"Roboto Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
  },
  text: {
    [TextVariant.H1]: {
      fontFamily: FontFamily.Mono,
      fontWeight: FontWeight.Medium,
      fontSize: [rem(30), null, rem(40), rem(52)],
      lineHeight: [1.27, 1.38],
      overflowWrap: 'break-word',
    },
    [TextVariant.H2]: {
      fontFamily: FontFamily.Mono,
      fontWeight: FontWeight.Medium,
      fontSize: [rem(26), rem(44)],
      lineHeight: 1.32,
      overflowWrap: 'break-word',
    },
    [TextVariant.H3]: {
      fontWeight: FontWeight.Medium,
      fontSize: [rem(18), rem(20)],
      lineHeight: [1.33, 1.5],
      overflowWrap: 'break-word',
    },
    [TextVariant.H4]: {
      fontWeight: FontWeight.Medium,
      fontSize: rem(15),
      lineHeight: 1.47,
      overflowWrap: 'break-word',
    },
    [TextVariant.Paragraph]: {
      fontWeight: FontWeight.Normal,
      fontSize: rem(15),
      lineHeight: 1.6,
      overflowWrap: 'break-word',
    },
    [TextVariant.Paragraph17]: {
      fontWeight: FontWeight.Normal,
      fontSize: rem(17),
      lineHeight: 1.53,
      overflowWrap: 'break-word',
    },
    [TextVariant.Paragraph12]: {
      fontWeight: FontWeight.Normal,
      color: Colors.Secondary,
      fontSize: rem(12),
      lineHeight: 1.6,
      overflowWrap: 'break-word',
    },
    [TextVariant.Alert]: {
      fontWeight: FontWeight.Normal,
      fontSize: rem(15),
      lineHeight: 1.3,
      overflowWrap: 'break-word',
    },
  },
};

export default typography;
