// Create a cache to hydrate the SSR rendered styles
import createCache from '@emotion/cache';

/**
 * Shared instance of Emotion cache. This should be the same on the server/client
 */
export const emotionCache = createCache({
  key: 'css',
});

// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557457527
emotionCache.compat = true;
