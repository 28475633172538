import React, { createContext, useCallback, useContext } from 'react';
import { Severity, captureMessage } from '@sentry/browser';

import { interpolate } from './helpers/interpolate';

const defaultValue = {};
export const DictionaryContext = createContext(defaultValue);

/* Map of all the missing keys, so we don't log any missing values more then once */
let missingMap: Object;

function missingKeyHandler(key: string) {
  if (!process.env.SERVER && (!missingMap || !missingMap[key])) {
    captureMessage(`Missing dictionary key: ${key}`, Severity.Warning);

    if (process.env.NODE_ENV !== 'test') {
      if (!missingMap) missingMap = { key: true };
      else missingMap[key] = true;
    }
  }
}

type DictionaryProviderProps = {
  items: Object;
  children?: React.ReactNode;
};

export const DictionaryProvider = ({
  items,
  children,
}: DictionaryProviderProps) => {
  const currentItems = useContext(DictionaryContext);

  return (
    <DictionaryContext.Provider
      value={currentItems ? { ...currentItems, ...items } : items}
    >
      {children}
    </DictionaryContext.Provider>
  );
};

/**
 * Recursively find a nested object by using the dot notation path.
 * This is a light version of the lodash _.get method.
 * If nothing is found on the path, it will return undefined.
 * */
function getProp(
  object: Object,
  path: string | string[],
): Object | string | undefined {
  const paths = Array.isArray(path)
    ? path
    : path.split('.').filter((i) => i.length);
  if (!paths.length) {
    return object;
  }
  const key = paths.shift();
  if (object === null || object === undefined || typeof key === 'undefined') {
    return undefined;
  }
  return getProp(object[key], paths) as string;
}

function findKeyValue(key: string, dictionary: Object, extraItems?: Object) {
  const isNested = key.includes('.');
  let value = isNested ? getProp(dictionary, key) : dictionary[key];
  if (!value && extraItems) {
    value = isNested ? getProp(extraItems, key) : extraItems[key];
  }

  return value;
}

/** Overload the `t` method so we get the correct Type back if `asString` is true. */
type IDictionaryOverload = {
  (
    key: string,
    params: Record<string, React.ReactNode> & { asString: true },
  ): string;
  (key: string, params?: undefined): string;
  (key: string, params?: Record<string, React.ReactNode>): React.ReactNode;
};

/**
 * useDictionary returns a `t` function that allows you to request the value of a Dictionary key.
 *
 * @example
 * ```
 * const {t} = useDictionary()
 * return t(Dictionary.login)
 * ```
 * */
export function useDictionary(extraItems?: Object) {
  const dictionary = useContext(DictionaryContext);
  if (process.env.NODE_ENV !== 'production' && dictionary === defaultValue) {
    throw new Error('useDictionary must be used within DictionaryContext');
  }

  const t = useCallback<IDictionaryOverload>(
    (key, params) => {
      const value = findKeyValue(key, dictionary, extraItems);

      if (!value) {
        // Handle a missing dictionary key
        missingKeyHandler(key);
        return `#${key}#`;
      }

      // Return the interpolated value. It's not strictly speaking correct to cast it as a `string`,
      // since it could be a ReactNode if it contains params. We'd have to assert this to determine what type of value.
      return interpolate(value, params);
    },
    [dictionary, extraItems],
  );

  /**
   * Check that a dictionary key exists
   * @param key
   */
  const exists = (key: string) => {
    return !!findKeyValue(key, dictionary, extraItems);
  };

  return { t, exists };
}
