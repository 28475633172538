import { Url, parse } from 'url';

// Exclude filenames
const fileName = /\/?[\w\d]+\.\w+$/;
// These paths are always external, even if they are under our domain. `/saml` paths are where we host Nemlogin
const externalPaths = /^\/(saml|api|static)/;

export function isLocalUrl(url: Url | string, target?: string): boolean {
  const parsed = typeof url === 'string' ? parse(url, false) : url;
  if (parsed.pathname) {
    if (fileName.test(parsed.pathname)) return false;
    if (externalPaths.test(parsed.pathname)) return false;
  }

  return !parsed.protocol && !parsed.host && (!target || target === '_self');
}

export function generateRedirectUrl(
  url: string,
  redirectUrl: string = document.location.href,
) {
  if (!url) {
    throw new Error('Tried to generated a redirect URL using a missing URL');
  }
  const { pathname = '/', search } = parse(redirectUrl, false);

  const returnUrl = pathname + (search || '');

  if (url.includes('##redirect##')) {
    // If the URL contains a "##redirect"" variable, just replace that and return the url.
    return url.replace('##redirect##', encodeURIComponent(returnUrl));
  }

  const parsed = parse(url);
  const params = new URLSearchParams(parsed.search || '');
  params.set('ReturnUrl', returnUrl);
  parsed.search = params.toString();

  return (
    (parsed.host ? `${parsed.protocol}//${parsed.host}` : '') +
    `${parsed.pathname}?${parsed.search}`
  );
}

export function normalizePathname(
  pathname: string | null,
  addTrailingSlash: boolean = false,
) {
  if (!pathname) return pathname;
  if (addTrailingSlash) {
    if (!pathname.endsWith('/')) {
      return pathname + '/';
    }
  } else if (pathname !== '/' && pathname.endsWith('/')) {
    return pathname.substr(0, pathname.length - 1);
  }

  return pathname;
}

export function normalizeUrl(
  url: string = '/',
  addTrailingSlash: boolean = false,
): Url {
  const parsed = parse(url, false);

  parsed.pathname = normalizePathname(parsed.pathname, addTrailingSlash);
  // Remove standalone '#' - Leftover from Umbraco
  if (parsed.hash === '#') parsed.hash = '';

  return parsed;
}
