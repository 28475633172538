import { CancelledError } from 'react-query';
import { parse } from 'url';

import { IError } from './api-types';

function headersToObject(headers: Headers) {
  const output = {};
  try {
    // `forEach` might not be supported in all browsers (like IE11)
    headers.forEach((value, key) => {
      output[key] = value;
    });
  } catch (e) {}
  return output;
}

function serializeResponse(response: Response) {
  return {
    status: response.status,
    statusText: response.statusText,
    url: response.ok,
    type: response.type,
    headers: headersToObject(response.headers),
  };
}

/**
 * Fetcher that enables SWR to fetch data from our API endpoints
 */
export async function fetcher<Result = Object>(
  url: string,
  options: RequestInit = {},
) {
  const headers: HeadersInit = {
    'content-type': 'application/json',
    // Never cache the requests locally.
    'cache-control': 'no-cache',
    pragma: 'no-cache',
    // Set credentials to ensure cookies are transferred
    credentials: 'same-origin',
    ...options?.headers,
  };

  if (!process.env.PROD && !!document.cookie) {
    // Make sure the cookie is sent along. This allows us to access it when using `fetch-mock` to mock the API calls.
    // It should only be done in production, when we are not using `fetch-mock`.
    headers['cookie'] = document.cookie;
  }

  const request: RequestInit = {
    method: options.body ? 'POST' : 'GET',
    ...options,
    headers,
  };
  // See if we can get a valid JSON error message from the response.
  const response = await fetch(url, request);
  const json: Result = await response.json().catch(() => {
    // Failed to parse a JSON response. Return an empty object instead.
    return {};
  });

  if (response.ok) {
    return json;
  } else {
    const { pathname } = parse(url);
    const msg = `[${response.status}] ${pathname}`;

    // Use a CancelledError here. Otherwise `react-query` logs out the message with `console.error`,
    // resulting a double log entry (since Sentry also captures those)
    const error: IError = new CancelledError();
    error.message = msg;
    error.status = response.status;
    error.pathname = pathname || '';
    error.response = request;
    error.response = serializeResponse(response);
    error.details = json;

    return Promise.reject(error);
  }
}
