import { Theme } from '@emotion/react';

import breakpoints from './breakpoints';
import buttons from './buttons';
import colors from './colors';
import typography from './typography';

/**
 * The theme should follow the System UI Theme Specification:
 * https://system-ui.com/theme/
 * and https://styled-system.com/theme-specification
 **/
const theme: Theme = {
  breakpoints,
  colors,
  space: [
    '0', // 0px
    '0.25rem', // 4px
    '0.5rem', // 8px
    '0.75rem', // 12px
    '1rem', // 16px
    '1.25rem', // 20px
    '1.5rem', // 24px
    '2rem', // 32px
    '2.5rem', // 40px
    '3rem', // 48px
    '4rem', // 64px
  ],
  buttons,
  sizes: {
    container: '80rem', // 1280px
  },
  variants: {
    container: {
      px: ['1.25rem', '2rem'],
    },
  },
  layout: {
    grid: {
      gridGap: ['1rem', '2rem'],
      gridColumns: [4, 8, 12],
    },
  },
  ...typography,
};

export default theme;
