import React, { useEffect } from 'react';
import { BaseCss } from '@charlietango/ui';
import { IdProvider } from '@charlietango/use-id';
import { CacheProvider, Global, ThemeProvider } from '@emotion/react';

import { AuthProvider } from '../api/AuthProvider';
import usePageData, { PageResult } from '../api/hooks/usePageData';
import { prefetchParties } from '../api/hooks/useParties';
import AuthMessages from '../components/AuthMessages/AuthMessages';
import StatusMessages from '../components/StatusMessages/StatusMessages';
import { DictionaryProvider } from '../hooks/useDictionary';
import { globalStyles } from '../styles/global-styles';
import theme from '../styles/theme';
import { HtmlConfigViewModel } from '../view-models/HtmlConfigViewModel';
import DocumentTitle from './DocumentTitle';
import { Modules } from './Modules';
import { useRouter } from './Router';
import ScrollRestoration from './ScrollRestoration';
import { emotionCache } from '../styles/emotion-cache';

type Props = {
  config: HtmlConfigViewModel;
  initialData: PageResult;
};

function App({ initialData, config }: Props) {
  const { pathname } = useRouter();
  const { data } = usePageData(pathname, {
    initialData,
  });

  useEffect(() => {
    // Regardless of the page we land on, start prefetching the parties so they are ready.
    prefetchParties();
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <AuthProvider
        authCookie={config.authCookie}
        loginUrl={config.loginUrl}
        logoutUrl={config.logoutUrl}
      >
        <DictionaryProvider items={config.dictionary || {}}>
          <IdProvider>
            <DocumentTitle />
            <ThemeProvider theme={theme}>
              {/* We only need to render the BaseCss on the server, since the global styles shouldn't change. If they could (e.g. the theme changes), then always output the <BaseCss> */}
              {process.env.SERVER ? (
                <>
                  <Global styles={globalStyles} />
                  <BaseCss />
                </>
              ) : (
                // Likewise we only need to render ScrollRestoration on the client
                <ScrollRestoration />
              )}
              {data && <Modules modules={data.modules} id={pathname} />}
              <StatusMessages />
              <AuthMessages />
            </ThemeProvider>
          </IdProvider>
        </DictionaryProvider>
      </AuthProvider>
    </CacheProvider>
  );
}

export default App;
