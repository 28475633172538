import { captureMessage, Severity, withScope } from '@sentry/browser';

import { IError } from './api-types';

export function handleQueryError(err: IError) {
  let logMessage = true;
  switch (err.status) {
    case 401:
    case 403:
    case 404:
      // Don't report these errors. They are expected.
      logMessage = false;
      break;
  }

  if (logMessage) {
    withScope((scope) => {
      scope.setTag('type', 'fetch');
      scope.setExtra('request', err.request);
      scope.setExtra('response', err.response);
      scope.setExtra('json', err.details);
      captureMessage(err.message || 'Query error', Severity.Warning);
    });
  }
}
