import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { hot } from 'react-hot-loader/root';
import useClientHydrated from '@charlietango/use-client-hydrated';
import { Action, History, Location, State, To } from 'history';
import { ReactQueryDevtools } from 'react-query-devtools';

import usePageData from '../api/hooks/usePageData';
import { normalizePathname, normalizeUrl } from '../utils/url-helpers';

export type RouteContextType = {
  action: Action;
  push: (path: To, state?: State) => void;
  replace: (path: To, state?: State) => void;
  pathname: string;
  search: string;
  /** The current search params for the URL */
  searchParams?: URLSearchParams;
  /** Update a set of KeyValues in the URL */
  updateSearchParams: (values: { key: string; value?: string }[]) => void;
  location: Location<any | undefined>;
};

export const RouteContext = createContext<RouteContextType | undefined>(
  undefined,
);

export type RouterProps = {
  location?: string;
  children?: React.ReactNode;
};

/***
 * Router exposes the history instance, allowing you to consume it with the Context API.
 * We are not using `react-router`, since we don't need the actual routing functionality - just the history.
 * ```
 */
function Router(props: RouterProps) {
  const history = React.useRef<History>();
  const hydrated = useClientHydrated();

  if (!history.current) {
    if (process.env.SERVER) {
      const createHistory = require('history').createMemoryHistory;

      history.current = createHistory({
        // Set the initial pathname from the current location.
        // It's important to set a valid value, since the server will crash otherwise
        initialEntries: [normalizeUrl(props.location).pathname || '/'],
      }) as History;
    } else {
      const createHistory = require('history').createBrowserHistory;
      history.current = createHistory() as History;
      if (props.location) history.current.replace(props.location);
    }
  }

  const currentHistory = history.current;
  const [, locationChanged] = React.useState<Location | null>(null);
  const loadedLocation = useRef(currentHistory.location);

  useEffect(() => {
    if (!history.current) return;
    const listener = history.current.listen(({ location }) => {
      if (history.current) locationChanged(location);
    });

    // We have been hydrated correctly now
    locationChanged(history.current.location);

    return () => listener();
  }, []);

  // Check if the page is still loading, and delay updating the location until the new data is ready.
  const { status } = usePageData(currentHistory.location.pathname, {
    // Skip this step when performing a test, since it causes an extra `act()`
    enabled:
      process.env.NODE_ENV !== 'test' && !!currentHistory.location.pathname,
    onError: () => {
      const location = currentHistory.location;
      if (location.pathname !== loadedLocation.current.pathname) {
        // If we didn't get a valid result, do a hard page refresh (but only if the page changed from the current one, e.g. don't do it on the initial route)
        window.location.assign(
          `${location.pathname}${location.search || ''}${location.hash || ''}`,
        );
      }
    },
  });

  if (status === 'success') {
    loadedLocation.current = currentHistory.location;
  }

  // We now know if the new location is ready or not. We use the currently loaded location until then.
  const location = loadedLocation.current;

  // Cache the search params so it's only update if the search string changes
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const updateSearchParams = useCallback(
    (values: { key: string; value?: string }[]) => {
      const newParams = new URLSearchParams(searchParams);
      values.forEach(({ key, value }) => {
        if (value) newParams.set(key, value);
        else if (newParams.has(key)) newParams.delete(key);
      });

      if (history.current) {
        const search = newParams.toString();
        const { pathname, hash, state } = loadedLocation.current;

        history.current.replace(
          {
            pathname,
            hash,
            search: search ? `?${search}` : undefined,
          },
          state,
        );
      }
    },
    [searchParams],
  );

  return (
    <RouteContext.Provider
      value={{
        action: currentHistory.action,
        replace: currentHistory.replace,
        push: currentHistory.push,
        pathname: normalizePathname(location.pathname) || '/',
        /* Because search is unknown on the search, delay setting it until we have hydrated the app */
        search: hydrated ? location.search : '',
        searchParams: hydrated ? searchParams : undefined,
        updateSearchParams,
        location: location,
      }}
    >
      {props.children}
      {!process.env.SERVER &&
      process.env.NODE_ENV === 'development' &&
      !process.env.STORYBOOK &&
      hydrated ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
    </RouteContext.Provider>
  );
}

Router.displayName = 'Router';

export function useRouter(): RouteContextType {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error('useRouter must be used inside a valid Router');
  }
  return context;
}

export default !process.env.HOT ? Router : hot(Router);
