import React from 'react';

type Props = {
  children: React.ReactNode;
  /** Href is the hash URL that skiplink should jump to. Most likely it will be main "#main" */
  href?: string;
  className?: string;
};

/**
 * Skip link allows the user to jump directly to the main content on the page, bypassing the header navigation.
 * It activates automatically on keyboard focus.
 * https://a11yproject.com/posts/skip-nav-links/
 **/
const SkipLink = ({ children, href = '#main', ...props }: Props) => (
  <a
    css={{
      left: '-999px',
      position: 'absolute',
      top: 'auto',
      height: '1px',
      width: '1px',
      overflow: 'hidden',
      outline: 'none',
      '&:focus': {
        position: 'fixed',
        background: '#e0e0e0',
        color: 'black',
        border: '1px dashed currentColor',
        top: 0,
        left: 0,
        padding: '1rem',
        width: '100%',
        height: 'auto',
        overflow: 'auto',
        textDecoration: 'underline',
        textAlign: 'center',
        zIndex: 10,
      },
    }}
    href={href}
    {...props}
  >
    {children}
  </a>
);

SkipLink.displayName = 'SkipLink';

export default SkipLink;
