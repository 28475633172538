import React, { useEffect, useMemo } from 'react';
import { Severity, captureMessage, withScope } from '@sentry/browser';

import SkipLink from '../components/SkipLink/SkipLink';
import { useDictionary } from '../hooks/useDictionary';
import { Colors } from '../styles/colors';
import ModuleTypes from '../view-models/ModuleTypes';
import ErrorBoundary from './ErrorBoundary';
import { ModuleInstance } from './modules-utils';

type Props = {
  id?: string;
  modules: ModuleInstance[];
};

type PreparedModules = {
  header?: ModuleInstance;
  footer?: ModuleInstance;
  modules: ModuleInstance[];
};

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

/**
 * Extract the modules that need special rules when rendering.
 * This allows us to maintain the header/footer between renders, and place them outside the <main />
 * */
function buildModuleStructure(modules: ModuleInstance[] = []): PreparedModules {
  const result: PreparedModules = {
    modules: [],
  };

  modules.forEach((mod) => {
    switch (mod.name) {
      case ModuleTypes.Header:
        result.header = mod;
        break;
      case ModuleTypes.Footer:
        result.footer = mod;
        break;
      default:
        result.modules.push(mod);
        break;
    }
  });

  return result;
}

function renderModule(item?: ModuleInstance, id?: string) {
  if (!item) return null;
  if (item.module) {
    const result = React.createElement(
      ErrorBoundary,
      { key: id || item.name, moduleName: item.name },
      React.createElement(item.module, item.properties),
    );

    if (item.schema) {
      return [
        result,
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: item.schema }}
        />,
      ];
    }

    return result;
  }

  // No matching module found - Log an error
  return process.env.NODE_ENV === 'development' ? (
    <div key={id || item.name}>Module {item.name} not found/ready</div>
  ) : null;
}

/**
 * Render all the modules on a single page.
 **/
export function Modules({ id, modules }: Props) {
  const { t } = useDictionary();
  const preparedModules = useMemo(() => buildModuleStructure(modules), [
    modules,
  ]);

  useEffect(() => {
    if (!preparedModules.header || !preparedModules.footer) {
      // Somethings missing!
      withScope((scope) => {
        scope.setExtra('modules', modules);
        if (!modules.length) {
          captureMessage('No modules in page data', Severity.Error);
        } else {
          captureMessage(
            'No Header and Footer module in page data',
            Severity.Error,
          );
        }
      });
    }
  }, [preparedModules, modules]);

  const keyMap: PartialRecord<ModuleTypes, number> = {};

  return (
    <div css={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SkipLink href="#main">{t('ui.skipNavigation')}</SkipLink>
      {renderModule(preparedModules.header)}
      <main
        id="main"
        tabIndex={-1}
        sx={{
          outline: 'none',
          flex: '1 1 auto',
          backgroundColor: Colors.White,
        }}
      >
        {preparedModules.modules.map((item) => {
          // Count the number of instances of each module type, so we can generate a unique key for each type,
          // while allowing a module to persist between pages.
          const moduleCount = keyMap[item.name] || 0;
          keyMap[item.name] = moduleCount + 1;

          return renderModule(item, `${id}_${item.name}_${moduleCount}`);
        })}
      </main>
      {renderModule(preparedModules.footer)}
    </div>
  );
}
