import { ComponentType, FunctionComponent } from 'react';

import { ModuleViewModel } from '../view-models/ModuleViewModel';

export type ModuleInstance = ModuleViewModel & {
  module: FunctionComponent<any> | ComponentType<any> | undefined;
};
/**
 * Flatten and return all unique module names
 **/
export function flatModulesList(
  modules: ModuleViewModel[] = [],
): Array<string> {
  return Array.from(new Set(modules.map((module) => module.name)).values());
}

/**
 * Ensure the modules are ready to render, by fetching the component constructor function
 * @param modules
 */
export function staticModuleInstances(
  modules: Array<ModuleViewModel> = [],
): ModuleInstance[] {
  if (process.env.SERVER || process.env.NODE_ENV === 'test') {
    // Ensure we only load the static modules map on the server
    const staticModulesMap = require('../modules/staticModulesMap').default;
    return modules.reduce<ModuleInstance[]>((acc, item) => {
      const module = staticModulesMap(item.name);
      acc.push({
        ...item,
        module,
      });
      if (!module) {
        // eslint-disable-next-line no-console
        console.error(`Tried to preload unknown React module: "${item.name}"`);
      }

      return acc;
    }, []);
  }

  return [];
}

/**
 * Ensure the modules are ready to render, by fetching the component constructor function
 * This is done lazy when rendering client side, so we can lazy import the modules we need
 * @param modules
 */
export async function preloadModules(
  modules: Array<ModuleViewModel> = [],
): Promise<ModuleInstance[]> {
  const loadedModules: ModuleInstance[] = [];

  if (!process.env.SERVER) {
    // Ensure we only load the dynamic modules map on the client
    const dynamicModulesMap = require('../modules/dynamicModulesMap').default;

    for (let i = 0; i < modules.length; i++) {
      const item = modules[i];
      const loader = dynamicModulesMap(item.name);
      const module = loader ? await loader() : undefined;
      loadedModules.push({
        ...item,
        module: module && module.default,
      });
      if (!module) {
        // eslint-disable-next-line no-console
        console.error(`Tried to preload unknown React module: "${item.name}"`);
      }
    }
  }

  return loadedModules;
}
