import { QueryResult, queryCache, useQuery } from 'react-query';

import { SchemaPartiesResponse } from '../api-schema';
import { ElectionType, IError } from '../api-types';
import { fetcher } from '../fetcher';

const queryKey = 'parties';

function loadParties(key: string) {
  return fetcher('/api/v1/parties');
}

export async function prefetchParties() {
  try {
    return queryCache.prefetchQuery(queryKey, loadParties, {
      staleTime: 1000 * 60 * 5,
      retry: false,
    });
  } catch (e) {}
}

/**
 * Get a list of all parties
 * */
export function useParties(
  type?: ElectionType,
): QueryResult<SchemaPartiesResponse, IError> {
  const result = useQuery<SchemaPartiesResponse, IError>(queryKey, loadParties);

  let items = result.data?.items;

  if (result.data && type !== undefined) {
    items = result.data?.items?.filter((party) => party.type === type);
    return { ...result, data: { items } };
  }

  return result;
}

useParties.displayName = 'useParties';
