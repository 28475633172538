import React, { Suspense, useEffect, useState } from 'react';

import { UserState, useAuth } from '../../api/AuthProvider';
import { useDictionary } from '../../hooks/useDictionary';
import { ButtonVariant } from '../../styles/buttons';
import RichText from '../RichText/RichText';

export type AuthMessagesProps = {};

// We lazy load the Modal - We most likely won't need it
const Modal = !process.env.SERVER
  ? React.lazy(() => import('../Modal/Modal'))
  : null;

/**
 * Show messages to user, relating to the authentication state.
 * Right now this is limited to session expired.
 */
function AuthMessages(props: AuthMessagesProps) {
  const { t } = useDictionary();
  const { userState, logoutUrl } = useAuth();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (userState === UserState.SessionExpired) {
      setShowMessage(true);
    }
  }, [userState]);

  if (userState === UserState.SessionExpired && Modal !== null) {
    return (
      <Suspense fallback={null}>
        <Modal
          isOpen={showMessage}
          onDismiss={() => setShowMessage(false)}
          title={t('errors.session_expired_title')}
        >
          <RichText>{t('errors.session_expired_text')}</RichText>
        </Modal>
      </Suspense>
    );
  }

  if (userState === UserState.Forbidden && Modal !== null) {
    return (
      <Suspense fallback={null}>
        <Modal
          isOpen
          title={t('errors.forbidden_title')}
          actions={[
            {
              label: t('ui.logout'),
              href: logoutUrl,
              variation: ButtonVariant.Primary,
            },
          ]}
        >
          <RichText>{t('errors.forbidden_text')}</RichText>
        </Modal>
      </Suspense>
    );
  }

  return null;
}

export default AuthMessages;
