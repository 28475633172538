import Plausible, { PlausibleOptions } from 'plausible-tracker';
import { EventOptions } from 'plausible-tracker/src/lib/request';

export enum PlausibleGoals {
  Party = 'Parti visninger',
}

let instance: ReturnType<typeof Plausible> | undefined;

export function initPlausible(domain?: string) {
  if (!domain) return;
  instance = Plausible({
    trackLocalhost: true,
    domain,
  });

  // This tracks the current page view and all future ones as well
  instance.enableAutoPageviews();
}

export function trackEvent(
  eventName: PlausibleGoals | string,
  options?: EventOptions,
  eventData?: PlausibleOptions,
) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info('plausible', eventName, options);
  } else if (instance) {
    return instance.trackEvent(eventName, options, eventData);
  }
}
