import React from 'react';
import { keyframes } from '@emotion/react';
import { rem } from 'polished';

type Props = {
  className?: string;
  instant?: boolean;
  size?: string | number;
};

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

function Spinner({ className, instant, size = rem(56) }: Props) {
  return (
    <span
      className={className}
      sx={{
        width: size,
        height: size,
        display: 'block',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <span
        sx={{
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          position: 'absolute',
          transformOrigin: 'center',
          display: 'inline-block',
          borderTop: '4px solid rgba(0, 183, 164, 0.2)',
          borderRight: '4px solid rgba(0, 183, 164, 0.2)',
          borderBottom: '4px solid rgba(0, 183, 164, 0.2)',
          borderLeft: '4px solid #00b7a4',
          borderRadius: '50%',
          animationDelay: instant ? '0s' : '.3s',
          animation: instant
            ? `${rotate} 1s linear infinite normal`
            : `${fade} .3s ease-in, ${rotate} 1s linear infinite normal`,
          willChange: instant ? 'transform' : 'transform, opacity',
        }}
      />
    </span>
  );
}

export default Spinner;
