import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  createDomMotionComponent,
  motion as originalMotion,
} from 'framer-motion';
import { CustomDomComponent } from 'framer-motion/types/render/dom';
import {
  HTMLMotionComponents,
  SVGMotionComponents,
} from 'framer-motion/types/render/dom/types';

type CustomMotionType = {
  custom: <Props>(
    Component:
      | string
      | React.ComponentClass<Props, any>
      | React.FunctionComponent<Props>,
  ) => CustomDomComponent<Props>;
};

type ValidMotionTypes = CustomMotionType &
  Pick<
    HTMLMotionComponents & SVGMotionComponents,
    // List all the exported HTML/SVG tags here. This ensures
    'div' | 'span' | 'button' | 'a' | 'article' | 'header' | 'svg' | 'path'
  >;

/**
 * Export a custom motion component to enable IE11 support.
 * Make sure to always use this object
 */
export const motion = {
  div: createDomMotionComponent('div'),
  span: createDomMotionComponent('span'),
  button: createDomMotionComponent('button'),
  a: createDomMotionComponent('a'),
  article: createDomMotionComponent('article'),
  header: createDomMotionComponent('header'),
  svg: createDomMotionComponent('svg'),
  path: createDomMotionComponent('path'),
  custom: originalMotion.custom,
} as ValidMotionTypes;

// re-export everything
export * from 'framer-motion'; // eslint-disable-line no-restricted-imports
