import React from 'react';

import Spinner from '../Spinner/Spinner';

export type PlaceholderProps = {
  className?: string;
  size?: 'small' | 'normal' | 'fullscreen';
};

/**
 *
 * The `<Placeholder/>` is meant to be used when fetching or waiting for any of api request.
 * It can be used to substitute an area of content or the whole page if needed.
 * A spinner will be shown inside the Placeholder, and will always be centered.
 * You can style the placeholder, to accomodate the content it's substituting, with fx a custom height, or what is needed.
 */

function Placeholder({ className, size }: PlaceholderProps) {
  if (size === 'fullscreen') {
    return (
      <div
        css={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          background: 'rgba(221,226,226, 0.7)',
          zIndex: 1,
          userSelect: 'none',
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={className}
      css={{
        position: 'relative',
        height: size === 'small' ? 200 : '40vh',
        maxHeight: '500px',
      }}
    >
      <Spinner />
    </div>
  );
}

export default Placeholder;
