export enum Colors {
  Primary = 'primary',
  Secondary = 'secondary',
  Black = 'black',
  VeryLightPink = 'veryLightPink',
  White = 'white',
  LightGrey = 'lightGrey',
  Grey = 'grey',
  PureWhite = 'pureWhite',
  BorderGrey = 'borderGrey',
  InfoGreen = 'infoGreen',
  DisabledGreen = 'disabledGreen',
  DisabledGreenText = 'disabledGreenText',
  BattleshipGrey = 'battleshipGrey',
  Warning = 'warning',
}

const colors = {
  [Colors.Primary]: '#00b9b0',
  [Colors.Secondary]: '#6d6d6d', // This replaces instances of Battleship Grey when it's used on a white background. The contrast would be too low.
  [Colors.BattleshipGrey]: '#757676',
  [Colors.Black]: '#333333',
  [Colors.VeryLightPink]: '#eeeeee', // Background color for the page, not fully White
  [Colors.White]: '#fafafa',
  [Colors.PureWhite]: '#fff',
  [Colors.LightGrey]: '#e2e2e2',
  [Colors.Grey]: '#d6d6d6',
  [Colors.PureWhite]: '#ffffff',
  [Colors.BorderGrey]: '#dde2e2',
  [Colors.InfoGreen]: '#ddf4f3',
  [Colors.DisabledGreen]: '#f2fbfb',
  [Colors.DisabledGreenText]: '#657171',
  [Colors.Warning]: '#fbf5d8',
  text: '#333333',
  background: '#fafafa',
};

export default colors;
