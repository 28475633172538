/* eslint-disable  no-redeclare */
import React from 'react';
import { Severity, captureMessage, withScope } from '@sentry/browser';

let missingMap: Object;

type InterpolationOptions = { asString?: boolean };
export type InterpolationParams = Record<string, React.ReactNode> &
  InterpolationOptions;

export function hasInterpolationValue(value: string) {
  return /{{?\s?(\w+)\s?}?}/.test(value);
}

function missingParamHandler(
  param: string,
  label: string,
  params?: InterpolationParams,
) {
  const key = `${label}_${param}`;
  if (!process.env.SERVER && (!missingMap || !missingMap[key])) {
    withScope((scope) => {
      scope.setExtras({
        params,
        param,
        label,
      });
      captureMessage(
        `Missing interpolation value for "${param}"`,
        Severity.Warning,
      );
    });

    if (process.env.NODE_ENV !== 'test') {
      if (!missingMap) missingMap = {};
      missingMap[key] = true;
    }
  }
}

/*
 * Convert an array of children into a string
 * */
export function childrenToString(children: React.ReactNode): string {
  if (typeof children === 'string') return children;
  const resultArray = React.Children.toArray(children);
  return resultArray
    .map((child) => {
      if (React.isValidElement(child)) {
        return child.props.children;
      }
      return child;
    })
    .filter((child) => {
      return typeof child === 'string';
    })
    .join('');
}

export function interpolate(label: string, params: undefined): string;

export function interpolate(
  label: string,
  params: InterpolationParams & { asString: true },
): string;
export function interpolate(
  label: string,
  params: InterpolationParams,
): React.ReactNode;
export function interpolate(label: string): string;
export function interpolate(
  label: string,
  params?: InterpolationParams,
): React.ReactNode {
  const split = label.split(/{{?\s?(\w+)\s?}?}/g);

  // No params to interpolate
  if (split.length === 1) return label;

  const output = split.map((match, index) => {
    // Because we are using split, only every second item is the actual match.
    if (index % 2 === 1) {
      if (!params || !params.hasOwnProperty(match)) {
        // The expect param is not part of the provided params
        missingParamHandler(match, label, params);
        return `{${match}}`;
      }
      // See if the params contain the match value. Otherwise we output the variable as is.
      let child = params[match];

      if (React.isValidElement(child)) {
        // If this is a valid React component, inject the "key" into the interpolated value.
        // This is needed because we return an array of children.
        return React.cloneElement(child, { key: index });
      }

      return child;
    } else {
      // This is a string value located in between the params.
      return match || null;
    }
  });

  if (!params || params.asString) {
    return childrenToString(output);
  }

  return output;
}
