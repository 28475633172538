import { css } from '@emotion/core';

export const cookieBotStyles = css`
  #CybotCookiebotDialog .CookieCard {
    border-bottom: none !important;
  }

  #CybotCookiebotDialog .CookieCard:not(:first-child) {
    display: none;
  }

  a#CybotCookiebotDialogPoweredbyCybot {
    display: none;
  }
`;
