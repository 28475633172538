import { useEffect, useLayoutEffect, useRef } from 'react';
import { Action } from 'history';

import { scrollToElement } from '../utils/scroll-to-element';
import { useRouter } from './Router';

/**
 * Small component that listens for changes in the Router location, and ensures the viewport
 * is scrolled to the to top when changing path. Only runs when a new path is pushed (e.g. the user clicked a link).
 */
function ScrollRestoration() {
  const { action, location } = useRouter();
  const prevLocation = useRef(location);

  useLayoutEffect(() => {
    // The layout effect runs before rendering, so we can use this to update the scroll position early.
    if (
      action === Action.Push &&
      !location.hash &&
      location.pathname !== prevLocation.current.pathname
    ) {
      // Reset scroll to the top when PUSHing a new path
      scrollToElement('#app', undefined, true);
    }

    // Store a reference to location, so we can compare the pathname on a new render
    prevLocation.current = location;

    return () => {};
  }, [location, action]);

  useEffect(() => {
    if (action === Action.Push && location.hash) {
      // If the hash value changes, perform a smooth scroll
      scrollToElement(location.hash, 'smooth', true);
    }
  }, [location, action]);

  return null;
}

export default ScrollRestoration;
