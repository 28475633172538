import React, { forwardRef, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { format } from 'url';

import { prefetchPageDataCache } from '../../api/hooks/usePageData';
import { useRouter } from '../../application/Router';
import { motion } from '../../utils/motion';
import { isLocalUrl, normalizeUrl } from '../../utils/url-helpers';
import LinkViewModel from '../../view-models/LinkViewModel';
import { trackEvent } from '../../utils/tracking';

export type NativeMotionTypes =
  | 'style'
  | 'onUpdate'
  | 'onDragStart'
  | 'onDrag'
  | 'onDragEnd'
  | 'onAnimationStart'
  | 'onAnimationComplete'
  | 'onTap';

export type AnchorProps = LinkViewModel & {
  replaceHistory?: boolean;
  prefetch?: boolean;
  /** This Anchor is always going to be an External page */
  external?: boolean;
  /** @internal Intercept the Motion prop, so it's not added to the DOM */
  whileHover?: string;
} & Omit<React.PropsWithoutRef<JSX.IntrinsicElements['a']>, NativeMotionTypes>;

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const Anchor = forwardRef(
  (
    {
      href,
      children,
      label,
      title,
      target,
      onClick,
      replaceHistory,
      prefetch,
      external,
      whileHover,
      ...rest
    }: AnchorProps,
    ref: any,
  ) => {
    const { replace, push, pathname, search } = useRouter();
    const [inViewRef, inView] = useInView({
      threshold: 0.5,
      triggerOnce: true,
    });

    const normalized = normalizeUrl(href);
    const isLocal = !external && isLocalUrl(normalized, target);

    if (isLocal && normalized.pathname) {
      // If the URL is relative to the current site, sanitize the href
      href = format(normalized);
    }

    const setRef = useCallback(
      (node) => {
        if (prefetch && isLocal) inViewRef(node);
        if (typeof ref === 'function') ref(node);
        else if (ref) ref.current = node;
      },
      [ref, inViewRef, prefetch, isLocal],
    );

    const currentPage =
      isLocal &&
      pathname === normalized.pathname &&
      (normalized.search ? search === normalized.search : true)
        ? 'page'
        : undefined;

    useEffect(() => {
      // Prefetch the next page once the link enters the viewport
      if (inView && normalized.pathname)
        prefetchPageDataCache(normalized.pathname);
    }, [inView, normalized.pathname]);

    const handleClick = (event) => {
      if (onClick) {
        onClick(event);
      }

      if (href && !isLocal) {
        trackEvent('Outbound Link: Click', { props: { url: href } });
      }

      if (
        href &&
        isLocal &&
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        target !== '_blank' && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        // Block the normal anchor event
        event.preventDefault();

        // Create a To object with the URL values. This ensures the correct values are used when we update the router location.
        const to = {
          hash: normalized.hash || undefined,
          pathname: normalized.pathname || undefined,
          search: normalized.search || undefined,
        };

        if (replaceHistory && replace) {
          replace(to);
        } else {
          push(to);
        }
      }
    };

    return (
      <a
        aria-current={currentPage}
        ref={setRef}
        href={href}
        title={title !== label ? title : undefined}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        target={target}
        sx={{
          fontSize: 'inherit',
          color: 'currentColor',
          overflowWrap: 'break-word',
        }}
        onClick={handleClick}
        {...rest}
      >
        {children || label}
      </a>
    );
  },
);

/**
 * Version of the Anchor that supports framer-motion
 * */
export const MotionAnchor = motion.custom(Anchor);

Anchor.displayName = 'Anchor';

export default Anchor;
