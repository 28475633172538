import { useQuery } from 'react-query';

import { SchemaFrontendUserDataViewModel } from '../api-schema';
import { IError } from '../api-types';
import { UserState, useAuth } from '../AuthProvider';
import { fetcher } from '../fetcher';
import { handleQueryError } from '../query-error';

/**
 * Get the logged in user - Undefined if user is not logged in
 * */
export const userQueryKey = 'user';

export function useUser() {
  const { userState, updateUserState } = useAuth();
  const enabled = userState === UserState.LoggedIn;

  const result = useQuery<SchemaFrontendUserDataViewModel | undefined, IError>(
    [userQueryKey, enabled],
    async (key) => {
      return fetcher('/api/v1/userdata');
    },
    {
      enabled,
      // Validate once focus is restored to the window
      refetchOnWindowFocus: true,
      // Validate the user every 10 minutes
      refetchInterval: 1000 * 60 * 10,
      // Don't validate in the background - User needs to interact with the site
      refetchIntervalInBackground: false,
      staleTime: 1000 * 30,
      onError: (error) => {
        handleQueryError(error);
        if (updateUserState) {
          if (error.status === 401) updateUserState(UserState.SessionExpired);
          else if (error.status === 403) updateUserState(UserState.Forbidden);
          else updateUserState(UserState.LoggedOut);
        }
      },
      retry: (failureCount, error) => {
        if (failureCount >= 2) return false;
        // Never retry if the error is because of missing credentials
        if (error.status === 401 || error.status === 403) return false;
        // retry after 5 seconds
        return true;
      },
    },
  );

  return {
    user: result.data,
    userReady:
      userState === UserState.LoggedIn
        ? // If the user is logged in, wait for the result
          result.isSuccess
        : // Else wait for the userState to be determined
          userState !== undefined,
  };
}

useUser.displayName = 'useUser';
