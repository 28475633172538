import { css } from '@emotion/react';

import { cookieBotStyles } from './cookiebot-style';
import { fonts } from './fonts';

export const globalStyles = css`
  ${fonts};
  ${cookieBotStyles}

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  :focus:not([data-focus-visible-added]) {
    outline: none;
  }

  dd,
  dd,
  dt {
    margin: 0;
  }
`;
