import addShims from './polyfills/shims';

/**
 * Load additional polyfills here
 **/
function loadPolyfills(): Promise<any> {
  addShims();
  const polyfills: Promise<any>[] = [];

  if (!window.fetch) {
    polyfills.push(import('unfetch/polyfill/index'));
  }

  if (!supportsIntersectionObserver()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'intersection-observer' */ 'intersection-observer'
      ),
    );
  }

  if (!supportsUrlSearchParams()) {
    polyfills.push(
      import(/* webpackChunkName: 'url-polyfill' */ 'url-polyfill'),
    );
  }

  return Promise.all(polyfills);
}

function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  );
}

function supportsUrlSearchParams() {
  try {
    const nativeURL = new window.URL('http://example.com');

    if ('href' in nativeURL && 'searchParams' in nativeURL) {
      const url = new URL('http://example.com');
      url.search = 'a=1&b=2';
      if (url.href === 'http://example.com/?a=1&b=2') {
        url.search = '';
        // @ts-ignore
        if (url.href === 'http://example.com/') {
          const sp1 = new window.URLSearchParams('a=1');
          const sp2 = new window.URLSearchParams(sp1);
          if (String(sp2) === 'a=1') {
            return true;
          }
        }
      }
    }
    return false;
  } catch (error) {
    return false;
  }
}

export default loadPolyfills;
