const breakpoints = [
  '48em', // 768px - Tablets portrait and above
  '62em', // 992px - Tablets landscape and above
  '80em', // 1280px - Desktops up to 13' and above
  '90em', // 1440px - Max width, desktops HD and above
];

export enum Breakpoint {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

/**
 * Map the breakpoints to a key, so they can be used with the object syntax
 */
breakpoints[Breakpoint.sm] = breakpoints[0];
breakpoints[Breakpoint.md] = breakpoints[1];
breakpoints[Breakpoint.lg] = breakpoints[2];
breakpoints[Breakpoint.xl] = breakpoints[3];

export const queries = {
  [Breakpoint.sm]: `(min-width: ${breakpoints[0]})`,
  [Breakpoint.md]: `(min-width: ${breakpoints[1]})`,
  [Breakpoint.lg]: `(min-width: ${breakpoints[2]})`,
  [Breakpoint.xl]: `(min-width: ${breakpoints[3]})`,
};

export type QueryMatch = {
  [key in Breakpoint]?: boolean;
};

export default breakpoints;
