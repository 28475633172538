/**
 * Scroll to a DOM element, and sets focus to the closest tabIndex
 **/
export function scrollToElement(
  /* Either the element id or a reference to the node */
  element: string | HTMLElement,
  behavior?: ScrollBehavior,
  focus: boolean = true,
) {
  const node =
    typeof element === 'string'
      ? document.getElementById(
          element.charAt(0) === '#' ? element.substr(1) : element,
        )
      : element;

  if (node) {
    // Store the current scroll position, before we mess with the focus
    const y = window.scrollY;

    if (focus) {
      const focusNode: HTMLElement | null = node.closest('[tabindex]');

      // Set focus to the area we deeplinked to. This will move the scroll position
      if (focusNode) focusNode.focus();
      // Reset the scroll position after setting focus, so we can animate the transition
      window.scroll(0, y);
    }

    // Scroll the element into view
    node.scrollIntoView({
      behavior,
      block: 'start',
    });
  } else {
    // Scroll the element to top if node is null
    window.scroll(0, 0);
  }
}
