import React, { memo } from 'react';
import { Heading, Text } from '@charlietango/ui';
import parseHtml, { DomElement, domToReact } from 'html-react-parser';
import { default as StyledSystem } from 'styled-system';

import {
  hasInterpolationValue,
  interpolate,
} from '../../hooks/helpers/interpolate';
import { TextVariant } from '../../styles/typography';
import Anchor from '../Anchor/Anchor';

type Props = {
  className?: string;
  children?: string;
  /** Interpolation params that the should be replaced in the RichText */
  params?: Record<string, React.ReactNode>;
  variant?: StyledSystem.ResponsiveValue<string>;
  headingsOffset?: number;
};

const isHtml = (html: string) => /<\/?[a-z][\s\S]*>/i.test(html);

/**
 * Use the `<RichText>` component to convert Rich Text HTML into React components from our UI library.
 * */
const RichTextHtml = memo(
  ({ children, variant, params, headingsOffset = 0, ...rest }: Props) => {
    if (!children) return null;

    const trimmedHtml = children
      // Normalize whitespace
      .replace(/[\f\n\r\t\v ]{2,}/g, ' ')
      // Remove linebreaks
      .split('\n')
      .join('')
      // FIX - Umbraco insists on outputting a '/' in links, making it impossible to create valid hrefs in Rich Text with
      // interpolated values. This ensures we remove any leading "/" from the href="" values.
      .replace(/href="\/{{?\s?(\w+)\s?}?}"/g, 'href="{$1}"')
      // Trim any extra whitespace
      .trim();

    function replaceHTML({
      attribs,
      children,
      name,
      data,
      parent,
      type,
    }: DomElement) {
      if (type === 'text') {
        if (params && hasInterpolationValue(data)) {
          return <>{interpolate(data as string, params)}</>;
        }
        if (!parent) return false;
      }

      // Don't modify these tags
      if (!attribs || !name || !children) return false;
      switch (name) {
        case 'a':
          if (params) {
            Object.keys(attribs).forEach((key) => {
              if (hasInterpolationValue(attribs[key])) {
                attribs[key] = interpolate(attribs[key], {
                  asString: true,
                  ...params,
                });
              }
            });
          }
          return (
            <Anchor {...attribs}>
              {domToReact(children, { replace: replaceHTML })}
            </Anchor>
          );
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
          const level = Math.min(2, parseInt(name.substr(1, 1)));
          const element = `h${level + headingsOffset}`;

          return (
            <Heading
              {...attribs}
              // @ts-ignore
              as={element}
              variant={level >= 3 ? TextVariant.H4 : TextVariant.H3}
              sx={{ mt: 7, mb: 4 }}
            >
              {domToReact(children, { replace: replaceHTML })}
            </Heading>
          );
        case 'ul':
        case 'ol':
        case 'p':
          return (
            <Text as={name} {...attribs} variant={variant} sx={{ my: 4 }}>
              {domToReact(children, { replace: replaceHTML })}
            </Text>
          );
        case 'li':
          return (
            <Text as="li" {...attribs} variant={variant} sx={{ my: 1 }}>
              {domToReact(children, { replace: replaceHTML })}
            </Text>
          );
      }

      return false;
    }

    return (
      <div
        {...rest}
        sx={{
          '> *:first-child': {
            mt: 0,
          },
          '> *:last-child': {
            mb: 0,
          },
        }}
      >
        {parseHtml(trimmedHtml, {
          replace: replaceHTML,
        })}
      </div>
    );
  },
);

/**
 * The `<RichText>` component takes an HTML string and renders the HTML tags using our React components.
 * If the children doesn't contain any HTML tags, it will still be wrapped in a `<Paragraph>`,
 * so you can safely use it to render text from the CMS that may or may not be HTML.
 **/
export const RichText = ({
  children,
  variant,
  params,
  headingsOffset,
  ...rest
}: Props) => {
  if (!children) return null;

  if (!isHtml(children)) {
    // If the children isn't actually HTML, we should just return the text wrapped in a `<p>`.
    return (
      <Text variant={variant} {...rest}>
        {params ? interpolate(children, params) : children}
      </Text>
    );
  }

  return (
    <RichTextHtml
      headingsOffset={headingsOffset}
      variant={variant}
      {...rest}
      params={params}
    >
      {children}
    </RichTextHtml>
  );
};

RichText.defaultProps = {
  variant: TextVariant.Paragraph,
};

export default RichText;
