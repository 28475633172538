import React, { Suspense, useEffect, useState } from 'react';

import { useRouter } from '../../application/Router';
import { useUser } from '../../api/hooks/useUser';
import { DeclarationActionTypes } from '../../view-models/DeclarationActionTypes';
import { MessageStatusTypes } from '../../view-models/MessageStatusTypes';
import Placeholder from '../Placeholder/Placeholder';
import { CollectionState } from '../../api/api-types';

const StatusMessageOverlay = !process.env.SERVER
  ? React.lazy(() => import('./StatusMessageOverlay'))
  : null;

export type StatusMessagesProps = {};

export type OverlayMessage = {
  status: MessageStatusTypes | null;
  action: DeclarationActionTypes | null;
  state?: CollectionState;
};

function shouldShowMessage(message: OverlayMessage) {
  // Always show error status, regardless of action
  if (message.status === 'error' && !message.state) return true;

  return (
    message.status &&
    message.action &&
    // If the user cancelled an action, we don't show anything.
    message.status !== MessageStatusTypes.cancelled
  );
}

function StatusMessages(props: StatusMessagesProps) {
  const { searchParams, updateSearchParams, replace } = useRouter();
  const { user } = useUser();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [message, setOverlayMessage] = useState<OverlayMessage | undefined>(
    undefined,
  );
  const isDataInitialized: boolean = user?.isDataInitialized ?? true;

  useEffect(() => {
    if (isDataInitialized) setShowModal(true);
  }, [user, isDataInitialized]);

  useEffect(() => {
    if (searchParams && searchParams.has('status')) {
      // We get the state if it is passed
      const messageState = searchParams.get('state');

      let message: OverlayMessage = { status: null, action: null };

      message = {
        action: searchParams.get('action') as DeclarationActionTypes,
        status: searchParams.get('status') as MessageStatusTypes,
        state: (messageState as unknown) as CollectionState,
      };

      updateSearchParams([
        {
          key: 'action',
          value: undefined,
        },
        { key: 'status', value: undefined },
        { key: 'state', value: undefined },
      ]);

      if (
        message.hasOwnProperty('action') &&
        message.hasOwnProperty('status') &&
        shouldShowMessage(message)
      ) {
        setOverlayMessage(message);
        setShowModal(true);
      }
    }
  }, [replace, searchParams, updateSearchParams]);

  //For the cases when there was an error initializing user's data
  if (StatusMessageOverlay !== null && !isDataInitialized) {
    return (
      <Suspense fallback={<Placeholder size="fullscreen" />}>
        <StatusMessageOverlay
          isOpen={showModal}
          status={'error' as MessageStatusTypes}
          onDismiss={() => {
            setShowModal(false);
          }}
        />
      </Suspense>
    );
  }

  if (
    message &&
    message.status &&
    StatusMessageOverlay !== null &&
    isDataInitialized
  ) {
    return (
      <Suspense fallback={<Placeholder size="fullscreen" />}>
        <StatusMessageOverlay
          isOpen={showModal}
          action={message.action}
          status={message.status}
          //For the cases when an error occurs when confirming or withdrawing a declaration
          state={message?.state}
          onDismiss={() => {
            setShowModal(false);
          }}
        />
      </Suspense>
    );
  }

  return null;
}

export default StatusMessages;
